<template>
  <canvas
    id="confetti-canvas"
    class="inset-0 fixed pointer-events-none h-full w-full z-10"
  ></canvas>
</template>

<script>
import { confettiConfig } from "../config";
export default {
  name: "ConfettiComponent",
  mounted() {
    this.$confetti.start(confettiConfig);
  },
};
</script>
