<template>
  <router-view />
</template>
<script setup>
import logo from "@/assets/logo.png";
import omImg from "@/assets/om.png";
import momoImg from "@/assets/momo.jpeg";

const images = [logo, omImg, momoImg];
images.map((image) => {
  const img = new Image();
  img.src = image;
  img.addEventListener("load", () => {});
});
</script>