<template>
  <div class="border-b border-t border-green py-4">
    <h1 class="md:text-lg xs:text-sm flex-1 mb-4 text-center">
      Si vous ne recevez pas de notification.
    </h1>
    <div class="flex items-center text-small">
      Cliquez ici
      <a href="tel:%23150%2A50%23" class="mx-2"
        ><img :src="om" alt="Om" class="h-6" />
      </a>
      ou tapez #150*50# pour Orange Money
    </div>
    <div class="flex items-center mt-2 text-small">
      Cliquez ici
      <a href="tel:%3A%2A126%23" class="mx-2"
        ><img :src="momo" alt="Momo" class="h-6"
      /></a>
      ou tapez *126# pour MTN MoMo
    </div>
  </div>
</template>

<script>
import om from "../assets/om.png";
import momo from "../assets/momo.jpeg";

export default {
  name: "PaymentInitSuccessComponent",
  data() {
    return {
      om,
      momo,
    };
  },
};
</script>
<style>
@media (max-width: 465px) {
  .text-small {
    font-size: 0.675rem;
  }
}
</style>
