<template>
  <div
    class="flex justify-center items-center w-full"
    style="background-color: rgba(255, 251, 197, 0.38)"
  >
    <div
      class="w-full h-full md:w-6/12 sm:w-8/12 px-1 mb-1 rounded bg-opacity-90 md:shadow-lg max-w-lg flex flex-col items-center justify-between h-100"
    >
      <section
        class="py-2 px-4 sm:py-10 sm:px-10 dark:text-white relative w-full z-20"
      >
        <div class="border-b border-green">
          <img
            class="mx-auto w-24 my-2"
            :src="logo"
            alt="logo"
            loading="eager"
          />
        </div>
        <dd v-if="codeIsOk">
          <div class="party-content" v-if="!responsePayment">
            <PaymentFailedComponent
              message="Cher client, le renouvellement de l'abonnement à votre Cameroon Tribune n'a pas abouti. Veuillez vérifier votre solde ou choisssez un autre forfait"
              v-if="paymentFailedStatus.includes(paymentStatus)"
            />
            <div class="py-4 grid gap-2 mb-2" v-else>
              <h1 class="text-xl text-center text-green underline font-bold">
                Abonnement / Réabonnement
              </h1>
              <img
                :src="upScaleImg"
                title="Cameroon Tribune"
                class="w-full h-60 object-contain sm:h-full"
              />
              <div class="text-center sm:px-4 px-2">
                <div v-html="title" />
              </div>
            </div>
            <LoaderComponent v-if="isLoading" />
            <div class="mb-8" v-else>
              <fieldset class="w-full border-t border-green">
                <legend class="px-2 text-base mx-auto">
                  {{ paymentMessage }}
                </legend>
                <p
                  class="p-4 bg-red-500 text-white rounded my-4 text-sm"
                  v-if="this.error"
                >
                  {{ this.error }}
                </p>
                <div
                  class="px-2 sm:px-8 text-center relative mb-4 mt-2 grid gap-3"
                >
                  <ListBoxComponent
                    :subscriptions="this.subscriptions"
                    v-model:value="subscriptionSelected"
                    :value="subscriptionSelected"
                  />
                  <InputNumberComponent
                    :value="phoneNumber"
                    v-model:value="phoneNumber"
                    v-model:error="error"
                  />
                  <ButtonPaymentComponent @click="pay()" />
                </div>
              </fieldset>
            </div>
          </div>
          <div v-else class="mt-10 mb-10">
            <div class="h-64 flex items-center">
              <p
                class="rounded-md text-white p-4 w-full text-center"
                :class="
                  (paymentInitSuccess && !this.error) ||
                  paymentStatus === 'SUCCESS'
                    ? 'bg-green '
                    : 'bg-red-500'
                "
              >
                {{ paymentResponseMessage }}
              </p>
            </div>

            <PaymentInitSuccessComponent v-if="paymentInitSuccess" />
          </div>
        </dd>
        <div v-else>
          <div class="text-center py-10 rounded-sm" v-if="error">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2em"
              viewBox="0 0 512 512"
              class="mx-auto mb-4"
            >
              <path
                d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
              />
            </svg>
            Vous éssayez d'acceder à des informations qui ne sont pas
            disponibles!
          </div>
          <div v-else class="text-center py-10 rounded-sm bg-white">
            <LoaderComponent />
          </div>
        </div>
      </section>

      <p class="text-center mb-4">
        <a
          href="https://nexah.net"
          class="text-black text-opacity-80 underline dark:text-white"
          style="font-size: 11px"
          >Fourni par NEXAH</a
        >
      </p>
    </div>
    <ConfettiComponent v-if="promotion" />
    <ModalComponent v-model:modalIsOpen="modalIsOpen" :img="promotion?.img" />
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import ListBoxComponent from "@/components/ListBoxComponent";
import PaymentInitSuccessComponent from "@/components/PaymentInitSuccessComponent";
import PaymentFailedComponent from "@/components/PaymentFailedComponent";
import PayIconComponent from "@/components/Icons/PayIconComponent";
import ButtonPaymentComponent from "@/components/ButtonPaymentComponent";
import InputNumberComponent from "@/components/InputNumberComponent";
import ModalComponent from "@/components/ModalComponent";
import ConfettiComponent from "@/components/ConfettiComponent";
import axios from "axios";
import logoImg from "@/assets/logo.png";
import omImg from "@/assets/om.png";
import momoImg from "@/assets/momo.jpeg";

export default {
  name: "RenewPackage",
  inject: ["apiUrl"],
  data() {
    return {
      isLoading: false,
      logo: logoImg,
      om: omImg,
      momo: momoImg,
      title:
        "Vous êtes sur le point de vous réabonner à votre quotidien Cameroon Tribune",
      paymentResponseMessage: "",
      paymentMessage: "Sélectionner votre forfait",
      responsePayment: false,
      paymentInitSuccess: false,
      interval: null,
      phoneNumber: null,
      error: null,
      subscriptions: null,
      subscriptionSelected: null,
      codeIsOk: false,
      paymentStatus: "",
      paymentFailedStatus: ["EXPIRED", "FAILED", "CANCELLED"],
      upScaleImg: null,
      modalIsOpen: false,
      promotion: null,
    };
  },

  mounted() {
    if (this.$route.params.code) {
      axios
        .get(`${this.apiUrl}/subscription-renew/${this.$route.params.code}`)
        .then((response) => {
          const img = new Image();
          img.src = response.data.upimg;
          const oldThis = this;
          img.addEventListener("load", (e) => {
            oldThis.displayPaymentView(response);
          });
          img.addEventListener("error", (e) => {
            oldThis.displayPaymentView(response);
          });
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
        });
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    async pay() {
      if (this.checkNumber() && this.subscriptionSelected) {
        this.isLoading = true;
        this.title =
          "Le renouvelement de votre abonnement au Cameroon Tribune est en cours...";
        this.paymentStatus = null;
        await axios
          .post(
            `${this.apiUrl}/subscription-renew/${this.$route.params.code}`,
            {
              paymentNumber: `${this.phoneNumber}`,
              subscriptionId: this.subscriptionSelected.id,
            }
          )
          .then((response) => {
            this.paymentResponseMessage =
              "Paiement initié avec succès. Veuillez patienter, vous allez recevoir une notification pour valider votre paiement";
            this.checkPayment(response.data.payment_ref);
            this.paymentInitSuccess = true;
            this.responsePayment = true;
          })
          .catch((error) => {
            this.title =
              "Vous êtes sur le point de vous réabonner à votre quotidien Cameroon Tribune";
            this.error =
              error.data?.message ??
              "Cher client, le renouvellement de votre abonnement n'a pas abouti. Veuillez essayer plus tard. Infoline: 699999095";
          });
        this.isLoading = false;
      } else {
        if (!this.subscriptionSelected) {
          this.error = "Sélectionner votre forfait";
        }
      }
    },
    checkNumber() {
      if (this.phoneNumber) {
        const regex = new RegExp(/^(237)?6((8|7|9)\d{7}|5([0-4]|[5-9])\d{6})$/);
        if (regex.test(this.phoneNumber)) {
          this.error = false;
          return true;
        }
        this.error =
          "Le numéro saisi ne correspond pas à un numéro MTN ou Orange";
      } else {
        this.error = "Vous devez renseigner votre numéro de téléphone";
      }
      return false;
    },
    checkPayment(payment_ref) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        axios
          .post(`${this.apiUrl}/pay-status/` + payment_ref, {
            code: this.$route.params.code,
          })
          .then((response) => {
            this.paymentStatus = response.data.status;
            if (response.data.status === "SUCCESS") {
              this.paymentResponseMessage =
                "Cher client, le renouvellement de votre abonnement au Cameroon Tribune a été éffectué avec succes, vous allez recevoir un message de confirmation avec tous les détails. Infoline: 699999095";
              clearInterval(this.interval);
              this.paymentInitSuccess = false;
              this.paymentStatus = "SUCCESS";
              this.error = false;
            } else if (
              this.paymentFailedStatus.includes(response.data.status)
            ) {
              clearInterval(this.interval);
              this.responsePayment = false;
              this.title = "";
              const index = this.subscriptions.findIndex(
                (subscription) =>
                  subscription.id === this.subscriptionSelected.id
              );
              if (index < this.subscriptions.length - 1) {
                this.subscriptionSelected = this.subscriptions[index + 1];
              }
              this.paymentMessage = "Choisir un autre forfait";
            }
          });
      }, 5000);
    },
    displayPaymentView(response) {
      this.subscriptions = response.data.subscriptions;
      this.subscriptionSelected = response.data.psub;
      this.codeIsOk = true;
      this.phoneNumber = response.data.phone;
      this.title = response.data.upmessage;
      this.upScaleImg = response.data.upimg;
      this.promotion = response.data.promotion;
      this.modalIsOpen = null !== response.data.promotion;
    },
  },
  components: {
    LoaderComponent,
    ListBoxComponent,
    PayIconComponent,
    PaymentInitSuccessComponent,
    ButtonPaymentComponent,
    PaymentFailedComponent,
    InputNumberComponent,
    ModalComponent,
    ConfettiComponent,
  },
};
</script>
<style scoped>
.shadow-dark {
  box-shadow: -1px 1px 5px 2px #8c8d8d;
}
</style>
