<template>
  <span><img :src="gift" alt="Gift" class="text-white w-8" /></span>
</template>

<script>
import giftImg from "@/assets/gift.png";
export default {
  name: "GiftComponent",
  data: () => {
    return {
      gift: giftImg,
    };
  },
};
</script>
