<template>
  <div class="py-10 px-4 text-red-500 text-center">
    <div class="flex justify-center font-semibold mb-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="{1.5}"
        stroke="currentColor"
        class="w-8 h-8 text-white"
        style="fill: rgba(239, 68, 68)"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      <span class="ml-1 text-xl border-b-2 pb-1 border-red-500">Oups</span>
    </div>
    <p>
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "PaymentFailedComponent",
  props: ['message']
};
</script>
