<template>
  <div class="absolute right-1 top-1">
    <span class="first triangle"></span>
  </div>
  <div class="absolute right-0 top-0">
    <span class="second triangle"></span>
  </div>
  <span class="absolute top-1 text-white" id="title">Best</span>
</template>

<script>
export default {
  name: "BestBadgeComponent",
};
</script>

<style scoped>
#title {
  rotate: 45deg;
  right: 4px;
  font-size: 12px;
  letter-spacing: -1px;
  font-weight: 500;
}
.triangle {
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-bottom: 3em solid transparent;
  display: block;
}
.first {
  border-right: 3em solid #f26522;
}
.second {
  border-right: 3em solid #f7941d;
}
</style>
