export const confettiConfig = {
  particles: [
    {
      type: "heart",
    },
    {
      type: "circle",
    },
    {
      type: "rect",
    },
  ],
  canvasId: document.getElementById("confetti-canvas"),
  particles: 10,
  particlesPerFrame: 0.75,
  defaultSize: 8,
}