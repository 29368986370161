import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/style.css'
import VueConfetti from 'vue-confetti';

const apiUrl = 'https://ms-sopecam.nexah.net/api/v1';

const app = createApp(App);
app.use(router)
    .use(VueConfetti)
    .provide('apiUrl', apiUrl)
    .mount('#app')
